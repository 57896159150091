/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"

// CSS Global Compulsory
import "../theme/html/assets/vendor/bootstrap/bootstrap.min.css"
// CSS Unify
import "../theme/html/assets/css/unify-core.css"
import "../theme/html/assets/css/unify-components.css"
import "../theme/html/assets/css/unify-globals.css"


const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <main>
      <>{children}</>
      <footer class="g-bg-black-opacity-0_9 g-color-white-opacity-0_8 text-center g-pt-60 g-pb-40">
        <div class="container">
          <div class="g-width-70x--md mx-auto">
            <p class="mb-0">
              Contact: Mikkel Jeppesen
              &nbsp;/&nbsp;
              <a href="mailto:mikkel@gregersfilm.dk">mikkel@gregersfilm.dk</a>
              &nbsp;/&nbsp;
              <a href="callto:+4520988546">+45 20 98 85 46</a>
            </p>
          </div>
        </div>

        <hr class="g-brd-white-opacity-0_2 g-my-40" />

        <div class="container">
          <small class="g-font-size-default">
            © {new Date().getFullYear()} Gregers Film
          </small>
        </div>
      </footer>

    </main>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
